<template>
  <div home-address v-if="info">
    <ProgressTitle :default-label="'onBoarding.title.homeAddress'" :is-final="true" />
    <CheckboxItem v-model="isSameAddress" theme="dark" @change="setAddress">{{ $t('onBoarding.contactAddress.homeAddressDescription') }}</CheckboxItem>
    <ValidationObserver ref="validator" v-slot="{ invalid }">
      <div class="field-holder">
        <ValidationComponent :label="$t('_.streetName')" name="_streetName" :component="TrimTextInput" :rules="streetNameRules" :preset="streetNamePreset" v-model="info.StreetName" :invalid="invalid" />
        <ValidationComponent :label="$t('_.streetNumber')" name="_streetNumber" :component="TrimTextInput" :rules="streetNumberRules" :preset="streetNumberPreset" v-model="info.StreetNumber" :invalid="invalid" />
      </div>
      <CzechCountrySelector permanent :model-info="info" :disable="isSameAddress" />
      <ValidationComponent :label="$t('_.postalCode')" name="_postalCode" :component="TrimTextInput" :rules="postalCodeRules" :preset="postalCodePreset" v-model="info.PostalCode" :invalid="invalid" />
      <ProgressButton button-name="next" @click="confirm()" :disabled="errorMsg || invalid" />
    </ValidationObserver>
  </div>
</template>

<script>
import ProgressTitle from '@/views/components/pages/on-boarding/ProgressTitle.vue'
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import CzechCountrySelector from '@/views/components/pages/on-boarding/template/CzechCountrySelector.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import Specific from '@shared/types/Specific';

export default {
  name: 'HomeAddress',
  lexicon: 'personalDetails',
  components: { ProgressButton, ValidationComponent, CzechCountrySelector, CheckboxItem, ProgressTitle },
  props: {
    invalid: { type: Specific, default: null },
  },
  data() {
    return {
      TrimTextInput,
      info: null,
      errorMsg: null,
      originAddress: {},
      originExtraAddress: {},
      streetNamePreset: Specific,
      streetNumberPreset: Specific,
      postalCodePreset: Specific,
      streetNameRules: Specific,
      streetNumberRules: Specific,
      postalCodeRules: Specific,
      isSameAddress: true,
      city: null,
    };
  },
  computed: {
    userInfo: state('user', 'userInfo'),
  },
  methods: {
    initialize() {
      this.initOriginAddress();
      this.initOriginExtraAddress();
      this.initPreset();
      this.initRules();
    },
    initRules() {
      this.streetNameRules = `requiredAddress:0,50,${this.info.CountryWritingSystem}`;
      this.streetNumberRules = `requiredAddress:0,20,${this.info.CountryWritingSystem}`;
      this.postalCodeRules = `requiredNumber:0,5`;
    },
    initPreset() {
      this.streetNamePreset = { maxLength: 50, disable: this.isSameAddress };
      this.streetNumberPreset = { maxLength: 20, disable: this.isSameAddress };
      this.postalCodePreset = { digit: true, maxLength: 5, disable: this.isSameAddress };
    },
    initOriginAddress() {
      this.originAddress.StreetName = this.info.StreetName;
      this.originAddress.StreetNumber = this.info.StreetNumber;
      this.originAddress.State = this.info.State;
      this.originAddress.City = this.info.City;
      this.originAddress.PostalCode = this.info.PostalCode;
    },
    initOriginExtraAddress() {
      this.originExtraAddress = { ...this.info.ExtraAddresses[0] };
    },
    setAddress() {
      this.initPreset();
      if (this.isSameAddress) this.setOriginAddress();
      else this.setExtraAddress();
    },
    setOriginAddress() {
      this.info.StreetName = this.originAddress.StreetName;
      this.info.StreetNumber = this.originAddress.StreetNumber;
      this.info.State = this.originAddress.State;
      this.info.City = this.originAddress.City;
      this.info.PostalCode = this.originAddress.PostalCode;
      this.city = this.info.City;
    },
    setExtraAddress() {
      this.info.StreetName = this.originExtraAddress.StreetName;
      this.info.StreetNumber = this.originExtraAddress.StreetNumber;
      this.info.State = this.originExtraAddress.State;
      this.info.City = this.originExtraAddress.City;
      this.info.PostalCode = this.originExtraAddress.PostalCode;
    },
    async confirm() {
      const payload = { State: this.info.State, City: this.info.City || this.city, PostalCode: this.info.PostalCode, StreetName: this.info.StreetName, StreetNumber: this.info.StreetNumber, AddressType: 'HomeAddress' };
      await this.$services.onboarding.editPersonalExtraAddress({ ExtraAddresses: payload });
      this.$emit('change-view', { goMailingView: true, homeAddressInfo: payload });
    },
  },
  async mounted() {
    this.info = { ...this.userInfo.PersonalInfo };
    this.initialize();
    if (this.info.HasExtraAddress) {
      this.isSameAddress = false;
      this.setExtraAddress();
      this.initPreset();
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[home-address] {
}
</style>
