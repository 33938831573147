<template>
  <div data-box v-if="info">
    <ProgressTitle :default-label="'onBoarding.title.dataBox'" :is-final="true" />
    <ValidationObserver ref="validator" v-slot="{ invalid }">
      <div class="field-holder">
        <ValidationComponent :label="$t('_.dataBoxNumber')" name="_dataBoxNumber" :component="TrimTextInput" :preset="dataBoxNumberPreset" v-model="info.DataBoxNumber" :error-msg="dataBoxNumberErrorMsg" :invalid="invalid" />
      </div>
      <ProgressButton button-name="next" @click="confirm" :disabled="errorMsg || invalid"/>
    </ValidationObserver>
  </div>
</template>

<script>
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';
import TrimTextInput from '@shared/components/common/input/TrimTextInput.vue';
import CzechCountrySelector from '@/views/components/pages/on-boarding/template/CzechCountrySelector.vue'
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import ProgressTitle from '@/views/components/pages/on-boarding/ProgressTitle.vue';
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'DataBox',
  lexicon: 'onBoarding.contactAddress',
  components: { ProgressButton, ValidationComponent, CzechCountrySelector, ProgressTitle },
  props: {
    invalid: { type: Specific, default: null },
    homeAddress: { type: Specific, default: null },
    mailingAddress: { type: Specific, default: null },
  },
  data() {
    return {
      info: null,
      errorMsg: null,
      dataBoxNumberPreset: null,
      dataBoxNumberErrorMsg: null,
      TrimTextInput,
    };
  },
  computed: {
    userInfo: state('user', 'userInfo'),
  },
  methods: {
    initialize() {
      this.initPreset();
      this.initErrorMsg();
    },
    initPreset() {
      this.dataBoxNumberPreset = { maxLength: 50 };
    },
    initErrorMsg() {
      this.dataBoxNumberErrorMsg = null;
    },
    async confirm() {
      const payload = this.mailingAddress;
      await this.$services.onboarding.editPersonalExtraAddress({ ExtraAddresses: payload ,DataBoxNumber: this.info.DataBoxNumber });
      this.$emit('change-view', { goUpdatedView: true });
    },
  },
  async mounted() {
    this.info = { ...this.userInfo.PersonalInfo };
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[data-box] {
}
</style>
